export const GENDERS = {
  MALE: 'male',
  FEMALE: 'female'
};

export const PARENT_TERMS = {
  DAM: 'dam',
  SIRE: 'sire'
};

export const PARENT_TERMS_PLURAL = {
  DAMS: 'dams',
  SIRES: 'sires'
};

export const PHOTO_MEDIA_CATEGORY_SLUGS = {
  PUPPIES: 'puppies',
  EVERYDAY_LIFE: 'everyday-life',
  ALL_GROWN_UP: 'all-grown-up',
  PAST_LITTER: 'past-litter',
  CURRENT_LITTER: 'current-litter'
};

export const PAYMENT_STATUS_TYPES = {
  UNPAID: 'unpaid',
  PAID: 'paid',
  DEPOSIT: 'deposit'
};

export const COLOR_FOR_PAYMENT_STATUS_TYPE = {
  [PAYMENT_STATUS_TYPES.UNPAID]: 'red',
  [PAYMENT_STATUS_TYPES.PAID]: 'green',
  [PAYMENT_STATUS_TYPES.DEPOSIT]: 'yellow'
};

export const PAYMENT_PAGE_TYPES = {
  COMPLETED: 'completed',
  PENDING: 'pending',
  SCHEDULED: 'scheduled'
};

export const PAYOUT_TYPES = {
  STRIPE: 'stripe',
  CHECK: 'check',
  PAYPAL: 'paypal'
};

export const PHOTOGRAPHABLE_TYPES = {
  BREEDER_PROFILE: 'BreederProfile',
  LITTER: 'Litter'
};

export const PHOTO_ALBUM_TYPES = {
  LITTER: 'litter',
  BREEDER: 'breeder',
  BREEDER_BREED: 'breeder_breed'
};

export const PHOTO_TYPES = {
  PROFILE: 'profile',
  GALLERY: 'gallery'
};

export const FAMILY_STATES = {
  APPROVED: 'approved',
  DENIED: 'denied',
  PAST: 'past',
  NEW: 'new',
  CURRENT: 'current', // includes [new, approved]
  INACTIVE: 'archived'
};

export const BUYER_APPLICATION_STATES = {
  WAITING: 'waiting',
  ARCHIVED: 'archived'
};

export const BREEDER_WAITLIST_STATES = {
  ACTIVE: 'active',
  ARCHIVED: 'archived'
};

export const HELP_NUMBER = '1-855-446-6336';
export const HELP_EMAIL = 'help@gooddog.com';
export const SCHEDULING_EMAIL = 'scheduling@gooddog.com';
export const SPECIALISTS_EMAIL = 'specialists@gooddog.com';
export const BREEDER_TEAM_NUMBER = '1-385-308-0373';
export const BREEDER_TEAM_EMAIL = 'breederteam@gooddog.com';

export const PRICING_TYPES = {
  RANGE: 'range',
  SINGLE: 'single'
};
export const PRICING_LABELS = {
  [PRICING_TYPES.RANGE]: 'Puppy prices vary',
  [PRICING_TYPES.SINGLE]: 'All puppies are the same price'
};
export const PRICING_OPTIONS = Object.entries(PRICING_LABELS).map(
  ([key, label]) => ({
    value: key,
    title: label
  })
);

export const MEDIA_CATEGORY_SLUG_TO_PATH = {
  [PHOTO_MEDIA_CATEGORY_SLUGS.CURRENT_LITTER]:
    'current_litter_photographs_attributes',
  [PHOTO_MEDIA_CATEGORY_SLUGS.PAST_LITTER]: 'past_litter_photographs_attributes'
};

export const WAITLIST_PREFERENCE_TYPES = {
  SIZE: 'size',
  GENDER: 'gender',
  COLOR: 'color',
  COAT_TYPE: 'coat_type',
  PATTERN: 'pattern',
  GENERATION: 'generation',
  TAIL_TYPE: 'tail_type'
};

export const BREED_SPECIFIC_WAITLIST_PREFERENCE_TYPES = [
  WAITLIST_PREFERENCE_TYPES.SIZE,
  WAITLIST_PREFERENCE_TYPES.COLOR,
  WAITLIST_PREFERENCE_TYPES.COAT_TYPE,
  WAITLIST_PREFERENCE_TYPES.PATTERN,
  WAITLIST_PREFERENCE_TYPES.GENERATION,
  WAITLIST_PREFERENCE_TYPES.TAIL_TYPE
];

export const GENERAL_WAITLIST_PREFERENCE_TYPES = [
  WAITLIST_PREFERENCE_TYPES.GENDER
];

export const ORDERED_COVER_PHOTOS = {
  LARGE: 'large',
  MEDIUM: 'medium',
  SMALL: 'small'
};

export const COVER_PHOTO_RATIOS = {
  [ORDERED_COVER_PHOTOS.SMALL]: 0.65,
  [ORDERED_COVER_PHOTOS.MEDIUM]: 1,
  [ORDERED_COVER_PHOTOS.LARGE]: 1.65
};

export const APPLICATION_ACTIONS = {
  APPROVE: 'approve',
  DENY: 'deny',
  MAYBE: 'maybe'
};

export const APPLICATION_ACTIONS_TITLES = {
  APPROVE: 'Move forward',
  DENY: 'Archive',
  MAYBE: 'Maybe'
};

export const DOG_CROPPIE_OPTIONS = {
  viewport: {
    width: 300,
    height: 300,
    shape: 'square'
  },
  boundary: {
    width: 300,
    height: 300
  }
};

export const PAYMENT_REQUEST_CATEGORIES = {
  DEPOSIT: 'deposit',
  DOG_PAYMENT: 'dog_payment',
  OTHER: 'other'
};

export const PAYMENT_REQUEST_SUB_CATEGORIES = {
  transportation: 'Transportation',
  boarding_or_training: 'Boarding or Training',
  microchip: 'Microchip',
  registration: 'Registration',
  something_else: 'Something Else'
};

export const WAITLIST_MEMBER_STATUSES = {
  PRELIMINARY: 'preliminary',
  NEW: 'new',
  APPROVED: 'approved',
  DENIED: 'denied',
  PAST: 'past',
  ARCHIVED: 'archived'
};

export const NOTE_TYPES = {
  TEXT: 'text',
  CALL: 'call',
  PAYMENT: 'payment'
};

export const PAYMENT_REQUEST_ACTIONS = {
  APPROVE: 'approve',
  DECLINE: 'decline',
  CANCEL: 'cancel',
  CANCEL_SCHEDULED: 'cancel_scheduled'
};

export const REFUND_REQUEST_ACTION = {
  REFUND_REQUEST: 'refund_request'
};

export const PAYOUT_LOCATIONS = {
  CARD: 'card',
  BANK: 'bank_account'
};

export const BUSINESS_TYPES = {
  COMPANY: 'company',
  INDIVIDUAL: 'individual'
};

export const LITTER_COUNT_OPTIONS = {
  Zero: '0',
  One: '1',
  Two: '2',
  Three: '3',
  Four: '4',
  Five: '5',
  Six: '6',
  Seven: '7',
  Eight: '8',
  Nine: '9',
  Ten: '10',
  Eleven: '11',
  Twelve: '12',
  Thirteen: '13',
  Fourteen: '14',
  Fifteen: '15'
};

export const LINE_ITEM_TYPES = {
  CHARGE: 'charge',
  FEE: 'fee',
  PROCESSING_FEE: 'processing_fee',
  DISCOUNT: 'discount'
};

export const ONBOARDING_TEAM_EMAIL = 'onboarding@gooddog.com';
